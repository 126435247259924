//animation


@keyframes cerchio {
  0% {
    height: 0;
    width: 0;
    opacity: 0;
  }
  2% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  15% {
    opacity: 1;
  }
  100% {
    height: 100%;
    width: 100%;
    opacity: 0;
  }
}


.sect-nav-prodotti {
  margin: 40px 0;
  h4 {
    text-align: center;
    color: $grigio;
    text-transform: uppercase;
    font-size: 22px;
    margin: 0;
    @media#{$bp-min-768}{
      display: none;
    }
  }
}

.nav-prodotti {
  display: flex;
  align-items: flex-start;
  margin: 50px 0;
  justify-content: space-between;
  &__btn {
    display: inline-block;
    cursor: pointer;
    position: relative;
    text-align: center;
    flex-grow: 1;
    padding-bottom: 50px;
    max-width: 220px;

    img {
      width: 220px;
      height: auto;
      position: relative;
      z-index: 3;

      @media#{$bp-min-1024} {
        width: 150px;
      }
    }

    .cerchio-active {
      position: absolute;
      transform: translateY(-50%) translateX(-50%);
      top: 50%;
      left: 50%;
      width: 100%;
      z-index: 0;
      opacity: 0;
      transition: opacity 1s;

      &:after {
        padding-top: 100%;
        display: block;
        content: '';
        border-radius: 50%;
      }


      & > div {
        display: block;
        border-radius: 50%;
        border: 1px solid $grigio-chiaro;
        width: 100%;
        height: 100%;
        position: absolute;
        animation: cerchio 6s infinite;
        transform: translateY(-50%) translateX(-50%);
        top: 50%;
        left: 50%;
        &.into-1 {
          animation-delay: 0s;
        }
        &.into-2 {
          animation-delay: 2s;
        }
        &.into-3 {
          animation-delay: 4s;
        }
      }

    } //cerchio-active
    
    html.no-touchevents &:hover {
      .cerchio-active {
        opacity: 1;
      }
      
      .nav-prodotti__title {
        color: $blu;
      }
    }


  } //btn

  &__title {
    text-transform: uppercase;
    display: block;
    text-align: center;
    width: 180px;
    margin-left: auto;
    margin-right:auto;
    color: $grigio;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
}


