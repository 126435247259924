//fonts
@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed:400,600,700|Barlow:400,400i,600,600i');

$font-title: "Barlow Condensed";
$font-text: "Barlow";


//colors
$color-main: #D81217;
$color-main-hover: #d8291f;

$blu: #054A8B;
$blu-hover: #015891;

$verde: #8dc63f;

$grigio-superchiarissimo: #e6e6e6;
$grigio-chiarissimo: #f9f9f9;
$grigio-chiaro-2: #f2f2f2;
$grigio-chiaro: #aaa;
$grigio: #808080;
$grigio-scurissimo: #323232;
$grigio-scuro: #444;





$arancio: #ffb230;
$arancio-hover: #ff9818;

$color-facebook: #4267b2;
$color-facebook-hover: #2b61b2;

$azzurro: #5d92ff;
$azzurro-hover: #3bb7ff;


//mediaquery

$bp-min-768: "only screen and (min-width:768px)";
$bp-min-1024: "only screen and (min-width:1024px)";
$bp-min-1280: "only screen and (min-width:1280px)";
$bp-min-1366: "only screen and (min-width:1366px)";
$bp-min-1440: "only screen and (min-width:1440px)";
$bp-min-1600: "only screen and (min-width:1600px)";

$bp-max-350: "only screen and (max-width:350px)";
$bp-max-767: "only screen and (max-width:767px)";
$bp-max-1023: "only screen and (max-width:1023px)";
$bp-max-1279: "only screen and (max-width:1279px)";
$bp-max-1439: "only screen and (max-width:1439px)";


//media query height

$bp-max-h-768: "only screen and (max-height:768px)";
$bp-max-h-1024: "only screen and (max-height:1024px)";
