.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  padding: .7em 1.5em;
  outline: 0;
  cursor: pointer;
  font-family: $font-title;
  text-transform: uppercase;

  border: 0;
  text-decoration: none;
  border-radius: 3px;

  .iconfull {
    margin-left: 15px;
  }

  &.bordo-bianco {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
  }
  &.bordo-blu {
    background-color: transparent;
    color: $blu;
    border: 1px solid $blu;
  }
  &.blu {
    background-color: $blu;
    color: #fff;
    &:hover {
      background-color: $blu-hover;
    }
  }
  &.color-main {
    background-color: $color-main;
    color: #fff;
  }
}