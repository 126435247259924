.cover {
  height: 100vh;
  background-color: $blu;
  position: relative;
  overflow: hidden;

  &__layer {
    background-color: rgba(4, 38, 90, 0.6);
    position: absolute;
    width: 100%;
    height: 100%;
    //content: '';
    display: block;
    top: 0;
    left: 0;
    opacity: 0;
  }

  video {
    position: absolute;
    height: 100%;
    width: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }

  .cover-video-img {
    position: absolute;
    height: 100%;
    width: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    background-size: cover;
    background-position: center;
    opacity: 0;
  }

  .animazione-cover {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 3;
    width: 100%;
    max-width: 300px;
    text-align: center;
    font-size: 30px;
    font-family: $font-title;

    .cerchio-container {
      position: relative;
      width:140px;
      height:140px;
      margin: 0 auto;
      .cerchio {
        width: 100%;
        height: 100%;
        position: absolute;
        border: 6px solid $color-main;
        border-radius:50%;
        display: block;
        transform: scale(0);
        opacity: 0;
      }

      .cerchio-into {
        width: 10px;
        height: 10px;
        position: absolute;
        background-color: $color-main;
        border-radius:50%;
        top: 50%;
        left: 50%;
        margin-left: -5px;
        margin-top: -5px;
        display: block;
        transform: scale(0);
      }

    } //cerchio.container

    .scritta {
      color: #fff;
      margin: 0 auto;
      text-shadow: 0 0 20px rgba(0,0,0,.5);
      //overflow: hidden;

      span {
        display: block;

        &.span-move {
          opacity: 0;
        }
      }
      strong {
        display: block;
        font-size: 1.2em;
      }
      .logo-cover {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        img {
          width: 100%;
          height: auto;
        }
        svg {
          .lettera-left,
          .lettera-right {
            opacity: 0;
          }
          .cerchio-svg {
            path {
              opacity: 0;
            }
          }
        }//svg
      }
    } //scritta

    .btn {
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      font-size: .55em;
    }

  } //animazione-cover


  @media#{$bp-min-1024}{
    video {
      min-width: 100%;
      min-height: 100%;
      height: auto;
      width: auto;
      //width: 100%;
      //height: auto;
    }
    .cover-video-img {
      min-width: 100%;
      min-height: 100%;
      height: auto;
      width: auto;
    }
    .animazione-cover {
      font-size: 40px;
      max-width: 500px;

      //text-align: left;
      //.scritta {
      //  .logo-cover {
      //    margin-left: 0;
      //  }
      //  .btn {
      //    margin-left: 0;
      //  }
      //}

      .cerchio-container {
        width: 300px;
        height: 300px;
        .cerchio {
          border-width: 8px;
        }
        .cerchio-into {
          width: 16px;
          height: 16px;
          margin-left: -8px;
          margin-top: -8px;
        }
      }
    } //animazione-cover
  }

}