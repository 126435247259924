@import 'variabili';
@import 'main_common';
@import 'button';

/*importo il css scaricato da fontastic*/
@import url(styles.css);

@import 'overlay';

@import 'header';

@import 'cover';

@import 'nav_prodotti';
@import 'sect_prodotti';

@import 'tab';


@import 'marketing_automation';
@import 'thankyou';


@import 'page';


@import 'footer';
