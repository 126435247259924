.sect-newsletter {
  padding: 40px 0;
  text-align: center;
  background: $grigio-chiarissimo;

  .title-section {
    color: $color-main;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.8em;
  }

  .subtitle-section {
    color: $grigio;
    font-family: $font-text;
    font-weight: 400;
    margin: 0 auto;
    font-size: 1.1em;
    max-width: 800px;
  }

  .btn-blog {
    display: inline-block;
    max-width: 300px;
    margin-top: 30px;
    font-size: 16px;
  }

  .content-col {
    img {
      margin-top: 30px;
      width: 100%;
      height: auto;
    }
  }

  @media#{$bp-min-1024}{
    padding: 80px 0;
    
    .content-col {
      display: flex;
      align-items: center;
      text-align: left;

      .right {
        width: 70%;
      }
    }

    .btn-blog:not(:last-child) {
      margin-right: 15px;
    }

    .title-section {
      font-size: 35px;
    }
  }
}


//da mandare



.sect-esperti {
  min-height: 70vh;
  padding: 30px 0;
  background-size: cover;
  background-position: 20% center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-size: 30px;
  position: relative;
  text-shadow: 0 0 8px rgba(0,0,0,.3);

  h4 {
    text-transform: uppercase;
    margin-top: 0;
  }

  .btn {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    border-width: 2px;
  }

  @media#{$bp-min-1024}{
    min-height: 60vh;
    background-position: center;

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      height: 100%;
      top: 0;
      width: 80%;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#001d42+0,001d42+100&0+0,0.65+100 */
      background: -moz-linear-gradient(left, rgba(0,0,10,0) 0%, rgba(0,0,10,0.65) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(0,0,10,0) 0%,rgba(0,0,10,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(0,0,10,0) 0%,rgba(0,0,10,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00001d42', endColorstr='#a6001d42',GradientType=1 ); /* IE6-9 */
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .content {
      width: 55%;
      margin-left: auto;
      h4 {
        font-size: 40px;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }

  } //1024
}

#modal-newsletter {
  max-width: 500px;
  overflow: hidden;

  iframe {
    height: 520px !important;
  }

  .cover-newsletter {
    padding: 20px 30% 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-size: cover;
    background-position: center bottom;
    font-size: 25px;
    color: $blu;
    background-color: #f2f2f2;
    border-bottom: 2px solid $blu;

    h4 {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1em;
      margin: 0;
      line-height: 1.2;
      margin-bottom: 0;
      img {
        height: .65em;
        width: auto;
        display: inline-block;
      }
    }
  }

  @media#{$bp-min-768}{
    .cover-newsletter {
      background-image: url(../images/cover-popup-newsletter.jpg);
      background-position: left center;
      padding-left: 180px;
      padding-right: 30px;
      font-size: 22px;
    }
  }
}

.box-modal, .box-modal__content {
  min-height: inherit !important;
}


#modal-esperti {
  max-width: 500px;
  overflow: hidden;

  iframe {
    height: 585px !important;
  }

  .icon-close {
    color: #fff;
  }

  .cover-esperti {
    padding: 40px 30% 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-size: cover;
    background-position: center bottom;
    font-size: 25px;
    color: #fff;
    text-shadow: 0 0 8px rgba(0,0,0,.2);
    h3 {
      font-size: 1.4em;
      margin-bottom: 10px;
      margin-top: 0;
      line-height: 1;
    }
    h4 {
      font-size: 1em;
      font-weight: lighter;
      margin: 0;
      line-height: 1;
    }


  }
}


//FORMATTO I LORO FORM --------------------

form {
  border: 0;
  width: 100%;
}

fieldset {
  border: 0;
  padding: 0;
}

form ul {
  list-style: none;
  padding: 0;
}

form ul li {
  margin-bottom: 20px;
}

form input {
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  outline: none;
  padding: 0 15px;
  border-radius: 2px;
}

form label {
  margin-bottom: 5px;
  display: block;
}

form {
  .field_value {
    input[type=text],
    input[type=password],
    textarea {
      border: 1px solid #ddd;

      &:hover {
        border: 1px solid #bbb;
      }
      &:focus {
        border: 1px solid #054A8B;
      }
    }
  }
}

form a {
  color: $color-main;
}

.checkbox-option  {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}
.checkbox-option input {
  width: auto !important;
  height: auto !important;
  margin: 0;
  margin-right: 10px;
}
.checkbox-option label {
  margin-top: -2px;
}

button#submit_button {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 18px;
  padding: .7em 1.5em;
  outline: 0;
  cursor: pointer;
  font-family: "Barlow", sans-serif !important;
  width: 100%;
  border: 0;
  text-decoration: none;
  border-radius: 3px;
  background: #054A8B !important;
  text-shadow: none !important;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 0;
}







