.page-footer {
  background-color: $blu;
  padding: 30px 0;
  font-size: 14px;
  text-align: center;
  color: #fff;

  &__logo {
    width: 150px;
    height: auto;
    display: block;
    margin: 0 auto 10px auto;
    img {
      width: 100%;
      height: auto;
    }
  }

  a {
    color: #fff;
    text-decoration: none;
  }
  .text {
    margin-bottom: 15px;
  }
  .social {
    justify-content: center;
    a {
      font-size: .8em;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }
}