
.page-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-120%);
  transition: transform .5s ease-in;
  z-index: 20;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 0 10px rgba(0,0,0,.15);
  }
  
  &__claim {
    display: none;
  }

  .container {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  &__logo {
    display: block;
    width: 130px;
    height: auto;
    margin: 0;
    a {
      display: block;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  @media#{$bp-min-1024}{
    &__claim {
      display: block;
      flex-grow: 1;
      margin: 0;
      margin-left: 20px;
      color: $blu;
      font-family: $font-text;
      font-weight: normal;
      font-size: 17px;
    }
  }

  html.header-transform & {
    transform: translateY(0);
    transition: transform .5s ease-out;
  }

} //page-header