

.page-content {
  padding-top: 70px;
  padding-bottom: 30px;
  .container {
    &.note-legali {
      min-height: calc(100vh - 300px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;
      text-align: center;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    td {
      border: 1px solid #ddd;
      padding: 10px;
    }
  }

  a {
    color: $color-main;
    text-decoration: none;
    word-break: break-word;
    &:hover {
      text-decoration: underline;
    }
  }
} //.page-content


.ovx {
  @media#{$bp-max-767}{
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */

  }
}