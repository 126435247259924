.tab-container {
  margin: 30px 0;
  border-top: 1px solid #ddd;
  padding-top: 40px;
}
.nav-tab {
  display: flex;
  justify-content: space-between;
  &__btn {
    text-align: center;
    cursor: pointer;

    h4 {
      margin: 0;
      text-transform: uppercase;
    }
    .iconfull {
      color: $color-main;
      margin-bottom: 10px;
      position: relative;
      transition: color .4s ease-out;
      font-size: 1.2em;

      &:before {
        z-index: 2;
        position: absolute;
      }

      &:after {
        background-color: $color-main;
        content: '';
        display: block;
        border-radius: 50%;
        transform: translateY(-50%) translateX(-50%) scale(0);
        top: 50%;
        left: 50%;
        position: absolute;
        width: 200%;
        height: 200%;
        z-index: 0;
        transform-origin: 50% 50%;
        transition: transform .5s ease-out;
      }
    }

    &.active {
      .iconfull {
        &:after {
          transition: transform .5s cubic-bezier(0.15, 2, 0.58, 1);
          transform: translateY(-50%) translateX(-50%) scale(1);
        }
        color: #fff;
      }
    }
  } //__btn
  
}


.wrapper-tab {
  border-top: 3px solid $color-main;
  margin-top: 20px;
  position: relative;
}

.selection-tab {
  top: -10px;
  text-align: center;
  position: absolute;
  height: 5px;
  transition: left .5s, width .5s;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $color-main transparent;
    position: absolute;
    top: 0;
    margin-left: -10px;
  }
}

.tab {
  display: none;
  padding: 10px;
  background-color: $grigio-chiaro-2;
  font-size: 1em;

  P {
    margin: 0;
    &:not(:last-child){
      margin-bottom: 1em;
    }
  }

  @media#{$bp-min-1024}{
    padding: 20px;
  }

  &.active {
    display: block;
  }
}