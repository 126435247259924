.overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 13, 22, 0.99);
  display: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  .bg-chiudi {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    cursor: default;
  }
  .box-modal {
    max-width: 100%;
    margin: 0 auto;
    background: #fff;
    left: 0;
    right: 0;
    position: absolute;
    overflow: hidden;
    z-index: 2;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    transition:all 0.5s;
    top: 0%;
    transform:translateY(-200%) translateX(0);

    &__header {
      padding: 20px;
    }

    &__content {
      padding: 20px;
    }

    .btn {
      width: auto;
    }

    &#modal-blog {
      max-width: 400px;
      text-align: center;
    }

    @media#{$bp-min-768}{
      width: 760px;
      border-radius: 3px;
      margin-bottom: 30px;
    }

    .icon-close {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #333;
      font-size: 13px;
      text-decoration: none;
      z-index: 100;
      &:hover {
        cursor: pointer;
        color: red;
      }
    }


    &.active {
      top: 0px;
      -ms-transform:translateY(0px) translateX(0);
      -moz-transform:translateY(0px) translateX(0);
      -webkit-transform:translateY(0px) translateX(0);
      transform:translateY(0px) translateX(0);
      @media#{$bp-min-768}{
        -ms-transform:translateY(30px) translateX(0);
        -moz-transform:translateY(30px) translateX(0);
        -webkit-transform:translateY(30px) translateX(0);
        transform:translateY(30px) translateX(0);
      }
      @media#{$bp-min-1024}{
        &.center {
          top: 50%;
          -ms-transform:translateY(-50%) translateX(0);
          -moz-transform:translateY(-50%) translateX(0);
          -webkit-transform:translateY(-50%) translateX(0);
          transform:translateY(-50%) translateX(0);
        }
      }
    } //active


  } //box-modal
}


