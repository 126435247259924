$margin-img-text: 50px;

.sect-prodotti {

    &:not(.prodotti-single){
      .prodotto {
        &:nth-child(odd){
          background-color: $grigio-chiaro-2;
          .tab {
            background-color: #fff;
          }

          @media#{$bp-min-1024} {
            .prodotto__content {
              order:2;
            }
            .prodotto__box-img {
              order:1;
              margin-right: 0;
              margin-left: $margin-img-text;
            }
          }//1024
        }

      } //prodotto
    }

  .prodotto {
    font-size: 17px;
    padding: 40px 0 60px 0;

    .intro {
      ul{
        padding-left: 19px;
      }
    }

    &__box-img {
      margin-top: -70px;
      position: relative;
      img {
        width: 100%;
        height: auto;
      }
      margin-bottom: 10px;
    }
    &__titolo {
      color: $blu;
      text-transform: uppercase;
      font-size: 1.5em;
      margin: 0;
      margin-bottom: 15px;

      &:after {
        content: '';
        display: block;
        width: 25px;
        height: 2px;
        border-radius: 1px;
        background-color: $color-main;
        margin-top: 15px;
      }
    } //titolo {
    &__sottotitolo {
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 1.2em;
    }

    .tab {
      font-size: 1em;
      line-height: 1.45;
      letter-spacing: .02em;

      h4 {
        margin-top: 0;
        margin-bottom: 5px;
        font-size: 1.1em;
        text-transform: uppercase;
      }

      ul {
        margin: 0;
        margin-bottom: 1em;
        padding: 0;
        list-style: none;
        li {
          position: relative;
          padding-left: 20px;
          margin-bottom: 5px;
          &:after {
            content: '';
            display: block;
            border-radius: 50%;
            width: 6px;
            height: 6px;
            background-color: $blu;
            position: absolute;
            left: 0;
            top: .6em;
          }

        } //li
      } //ul
    }
    
    .tasto-up {
      display: none;
    }

    @media#{$bp-min-1024}{
      padding: 40px 0 80px 0;


      &#crema {
        padding-bottom: 40px;
      }

      &__content {
        display: flex;
      }

      &__box-img {
        min-width: 45%;
        max-width: 45%;
        margin-right: $margin-img-text;
        position: relative;
        margin-top: 0;
        transform: translateY(-70px);
        transition: translateY 3s;
        img {
          top: 150px;
          position: sticky;
        }
      }

      &__text-content {
        flex-grow: 1;
      }

      //html.touchevents & {
        .tasto-up {
          margin-left: auto;
          margin-right: auto;
          font-size: 40px;
          margin-top: 50px;
          color: $grigio;
          //display: block;
          width: 1em;
          height: 1em;
          line-height:1;
          //opacity: 0;
          transition: transform 2s, opacity .5s;
          animation: float 2s infinite ease;
          cursor: pointer;
          position: absolute;
          right: 0;
          left: 0;
          bottom: 0;
        }
      //}

      &:hover {
        .tasto-up {
          opacity: 1;
        }
      }


    } //1024


  } //prodotto

  &.prodotti-single {
    padding-top: 80px;

    .prodotto {
      @media#{$bp-min-1024}{
        min-height: calc(100vh - 204px);
        padding: 60px 0 20px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    
    .tasto-up {
      display: none;
    }
  }


}



@keyframes float {

  0% { transform: translateY(-10px); }
  50% { transform: translateY(10px); }
  100% { transform: translateY(-10px); }

}