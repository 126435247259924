.sect-thank-you {
  padding-top: 80px;
  padding-bottom: 0px;
  min-height: calc(100vh - 185px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 5px solid $verde;
  text-align: center;
  font-size: 25px;

  .logo-benessere {
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 5px solid $blu;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
    img {
      max-width: 350px;
      height: auto;
      width: 100%;
    }
  }

  .title-section {
    color: $blu;
    margin: 0;
  }
  .subtitle-section {
    color: $blu;
    font-weight: lighter;
    margin-top: 10px;
  }

  .content-col {
    img {
      height: auto;
      width: 100%;
    }
  }

  @media#{$bp-min-1024} {
    .content-col {
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      padding: 60px 0;
      .left {
        text-align: center;
        font-size: 30px;
      }
      .right {
        width:60%;
        position: relative;
        //&:after {
        //  padding-top: 150%;
        //  content: '';
        //  display: block;
        //}
        img {
          //position: absolute;
          //margin: 0;
        }
      }
    }

  } //1024
  
  
  
  &.thankyou-download {
    padding-top: 100px;
    padding-bottom: 320px;
    justify-content: flex-start;
    background-color: #f2f2f2;
    background-image: url("../images/cover-thankyou-download.jpg");
    background-size: 170% auto;
    background-position: center bottom;
    background-repeat: no-repeat;

    .title-section {
      color: $color-main;
    }
    .subtitle-section {
      margin-bottom: 20px;
    }
    .btn {
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
      font-weight: bold;
    }

    @media#{$bp-min-1024} {
      padding-top: 100px;
      background-position: center bottom;
      background-size:  auto 75%;
      font-size: 30px;
      .content {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
      }

    }
  } //thankyou-download
  
}
